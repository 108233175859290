import api from "./api";

const formDataOptions = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

const responses = {
    getResponses(query,additionalStringQuery) {
        if(additionalStringQuery){
        return api.get(`/jobResponses?${additionalStringQuery}`, { params: query});

        }else {
        return api.get(`/jobResponses`, { params: query});

        }
    },
    getResponse(id) {
        return api.get(`/jobResponses/${id}`);
    },
    postStatus(responseId, statusId, body) {
        return api.post(`/jobResponses/${responseId}/setStatus/${statusId}`, body);
    },
    setStatusWithDocuments(responseId, statusId, body) {
        return api.post(`/jobResponses/${responseId}/setStatus/${statusId}`, body,formDataOptions);
    },
    getHistory(id) {
        return api.get(`/jobResponses/${id}/history`);
    },
    transferDecision(id,body) {
        return api.post(`/jobResponses/${id}/delayDeadline`,body);
    },
    postMakeResponse(body) {
        return api.post(`/jobResponses/make`,body);
    },
    getResponseDocuments(id,step) {
        return api.get(`/jobResponses/${id}/${step}`);
    },
};

export default responses;
